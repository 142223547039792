import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {  useHistory } from 'react-router-dom'

const Courses = () => {
    const history=useHistory()
    const [courses,setCourses]=useState([])
    useEffect(()=>{
       axios.get(` ${process.env.REACT_APP_API_URL}/courses/get`)
        .then((res)=>setCourses(res.data)) 
    },[])
    console.log(courses)
    const deletehandler=(e)=>{
        // console.log()
    // }
        // console.log(id)
            axios.delete(` ${process.env.REACT_APP_API_URL}/courses/delete/${e.target.parentNode.value}`,{
                headers:{
                    "x-access-token":localStorage.getItem("token")
                }
            })
             .then((res)=>console.log(res.data)) 
    }
    return (
        <div className="invoice">
             <h1 className="topdashboardheading">Courses</h1>
             <button className="addnewcourse" onClick={()=>history.push('/newcourse')}>Add Course</button>
             <div className="courseshow">
                 <span>
                    <p className="coursedata">Showing 45 Current Courses</p>
                    <p className="basedon">Based your preferences</p>
                 </span>
                 
                 <div className="filter">
                    <i class="fas fa-sort-amount-down"></i>
                    <p className="newest">Newest</p>

                 </div>
             </div>
            <table  className="invoicetable" cellPadding='40'>
                <thead  className="invoicetablehead">
                    <tr className="invoicetableheadrow">
                        <th className="tablecellhead">ID</th>
                        <th className="tablecellhead">Date</th>
                        <th className="tablecellhead">Tittle</th>
                        <th className="tablecellhead">Sub tittle</th>
                        <th className="tablecellhead">Skills</th>
                        <th className="tablecellhead">Actions</th>
                    </tr>  
                </thead>
                <tbody>
                    {courses.map((i)=>{
                        
                        return(
                            <tr className="invoicetableheadrow td">
                            <td className="tabledata idnumber">{i._id}</td>
                            <td className="tabledata">{i.Date}</td>
                            <td className="tabledata">{i.maintittle}</td>
                            <td className="tabledata">{i.sectittle}</td>
                            <td className="tabledata">{i.technologies}</td>
                            <td className="tabledata" ><i class="fas fa-edit download" onClick={()=>alert('edit')} ></i> <button style={{backgroundColor:"transparent",border:"none"}} value={i._id} onClick={(e)=>deletehandler(e)}><i  class="fas fa-trash-alt download"></i></button> <i class="far fa-file-pdf download" onClick={()=>alert('pdf')} ></i> </td>
                       </tr>  
                        )
                    })}     
                </tbody>
            </table>
        </div>
    )
}

export default Courses
