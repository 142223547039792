import React, { useEffect } from 'react'

import Topdashbar from '../../components/topdashbar/topdashbar'
import './dashboard.css';

import { useHistory } from 'react-router-dom';

const Dashboard = () => {
    const history=useHistory()
    useEffect(()=>{
        if(!localStorage.getItem('token')){
            history.push('/login')
        }
    })
    return (
        <>
            <div className="dashboard">
                <Topdashbar/>
                
            </div>
            
        </>
    )
}

export default Dashboard
