import axios from 'axios'
import React, { useEffect, useState } from 'react'
import './topdashbar.css'

const Topdashbar = () => {

    const [stu,setStu]=useState([])
    useEffect(()=>{
        axios.get(` ${process.env.REACT_APP_API_URL}/user/get`)
         .then((res)=>setStu(res.data)) 
     },[])
     const [courses,setCourses]=useState([])
    useEffect(()=>{
       axios.get(` ${process.env.REACT_APP_API_URL}/courses/get`)
        .then((res)=>setCourses(res.data)) 
    },[])
    console.log(courses)
    return (
        <div className="topdashbar">
            <h1 className="topdashboardheading">Dashboard</h1>
            <div className="dashboardboxes">
                <div className="dashboardbox">
                    <p className="numberof">Number of Students</p>
                    <h1 className="students">{stu.length}</h1>
                    <hr className="q"></hr>
                    <p className="seeall">See All Students</p>
                </div>
                <div className="dashboardbox">
                    <p className="numberof">Amount of payment</p>
                    <h1 className="students">$3000</h1>
                    <hr className="q"></hr>
                    <p className="seeall">See All Transaction</p>
                </div>
                <div className="dashboardbox">
                    <p className="numberof">Number of Courses</p>
                    <h1 className="students">{courses.length}</h1>
                    <hr className="q"></hr>
                    <p className="seeall">See All  Courses</p>
                </div>
                <div className="dashboardbox">
                    <p className="numberof">Number of Teachers</p>
                    <h1 className="students">80</h1>
                    <hr className="q"></hr>
                    <p className="seeall">See All  Teachers</p>
                </div>
            </div>
        </div>
    )
}

export default Topdashbar
