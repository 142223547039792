
import React, { useState } from 'react'
import './newp.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'


const Newpromocode = () => {
    async function handlerError(res){
        if(!res.ok){
            const message=await res.json();
            // console.log(message)
            throw Error(message)
        }
        return res.json();
    }
    const [prom,setProm]=useState('')
    const [dis,setDis]=useState()
    const [err,setErr]=useState()
    const data= JSON.stringify({promocode:prom.toUpperCase(),discount:dis}) 
    // console.log(data)
    async function pro(e){
        e.preventDefault()
        fetch(`${process.env.REACT_APP_API_URL}/promocode/post`,{
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                "x-access-token":localStorage.getItem("token")
            },
            body:data,
        })
         .then(handlerError)
         .then((re)=>{
            toast.success('added', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            //  window.location.reload();
            setProm('');
            setDis('');
            

        })
         .catch((err)=>{
             if(err){
                 console.log(err)
                 setErr(err.message)
             }else{
                 alert('none')
             }
         })
    }
    return (
        <div className="newp">
            <p className="npw-t">New Promocode</p>
            <form className="npc">
                <p>{err}</p>
                <input className="npcf" onChange={(e)=>setProm(e.target.value)} value={prom} style={{textTransform:"uppercase"}}  type="text" placeholder="PROMO CODE"></input>
                <input className='npcf' type="number" onChange={(e)=>setDis(e.target.value)} value={dis} placeholder="Discount"></input>
                <button type="submit" onClick={(e)=>pro(e)} className="npc-btn">Submit</button>
            </form>
            <ToastContainer/>
        </div>
    )
}

export default Newpromocode
