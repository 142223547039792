import React,{useState} from 'react'
import { useHistory } from 'react-router-dom';
import './login.css';

const Login = () => {
    
    const history=useHistory()
    async function handlerError(res){
        if(!res.ok){
            const message=await res.json();
            // console.log(message)
            throw Error(message)
        }
        return res.json();
    }
    const [email,setEmail]=useState('')
    const [password,setPassword]=useState('')
    const [err,setErr]=useState('')
    async function postregis(e){
        e.preventDefault()
        const data=JSON.stringify({email:email,password:password})
        // console.log(data)
        fetch(`${process.env.REACT_APP_API_URL}/user/login`,{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:data,
        })
         .then(handlerError)
         .then((re)=>{
             localStorage.setItem("token",re.token)
             history.push("/")
            //  window.location.reload();

        })
         .catch((err)=>{
             if(err){
                 setErr(err.message)
             }else{
                 alert('none')
             }
         })
    }
    return (
        <>
            <div className="logbox">
            <p>{err}</p>
                <form className='formlogbox' onSubmit={(e)=>postregis(e)}>
                    <input className='inputdata' type='email' onChange={(e)=>setEmail(e.target.value)}  placeholder='email Id'/>
                    <input className='inputdata' type='password' onChange={(e)=>setPassword(e.target.value)} placeholder='Password'/>
                    <button type='submit' className='logsub'>Sign In</button>
                </form>
             
            </div>
        </>
    )
}

const Cred=()=>{
    return(
        <>
            <div className='loginpage'>
                 <h1 className='logheading'>Sign In</h1> 

                <div className='logboxindca'>
                   
                    <div className="mainlog">
                        <Login/> 
                    </div>
                </div>
            </div>

        </>
    )
}

export default Cred;
