import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import './navbar.css';


const Navbar = () => {
    const [mob,setMob]=useState(true)
    return (
        <>
            <div className="navbar">
                <img  alt="Spinotech" className="brand-nav"/>
                <div className="searchdiv">
                    <span className="menurelated">
                        <i class="fas fa-bars navicon"/>
                        <p className="menuname">Menu</p>
                    </span>
                    <input className='searchbar' type='text' placeholder='search ...'/>
                </div>
                {mob ?
                <ul className='uldiv'>
                    <NavLink to='/' exact className="navlinkdash" activeClassName="nava">Dashboard</NavLink>
                    <NavLink to='/members' exact className="navlinkdash" activeClassName="nava">Members</NavLink>
                    <NavLink to='/report' exact className="navlinkdash" activeClassName="nava">Report</NavLink>
                    <NavLink to='/help' exact className="navlinkdash" activeClassName="nava">Help</NavLink>
                </ul>:null}
                <ul className="infodiv">
                    <li className="navli"> <i class="fas fa-bell navicon z"/> </li>
                    <li className="navli"> <i class="fas fa-cog navicon z"/> </li>
                    <li  className="navli for">
                        <img className="profile"  alt="profile pic" />
                        <span className="infodata">
                            <p className="username">Manohar</p>
                            <p className="usernamed">Super Admin</p>
                        </span>
                    </li>
                    
                </ul>
                <i class="fas fa-bars navicon resp" onClick={()=>setMob(!mob)}/>
            </div>
        </>
    )
}

export default Navbar
