import { Route,Switch,BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import Navbar from './components/navbar/navbar';
import Newcourse from './components/newcourse/newcourse';
import Newpromocode from './components/newpromocode/newpromocode';
import Sidebar from './components/sidebar/sidebar';
import Course from './pages/course/course';
import Dashboard from './pages/dashboard/dashboard';
import In from './pages/in/in';
import Cred from './pages/login/login';
import Promo from './pages/promo/promo';
import Studentp from './pages/studentp/studentp';

import Protected from './protected';



function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <Navbar/>
          <div className="otherpages">
            <Sidebar/>
            <Protected Comp={Dashboard}/>
          </div>
        </Route>
        <Route exact path='/Invoices'>
          <Navbar/>
          <div className="otherpages">
            <Sidebar/>
            <Protected Comp={In}/>
          </div>
        </Route>
        <Route exact path='/student'>
          <Navbar/>
          <div className="otherpages">
            <Sidebar/>
            <Protected Comp={Studentp}/>
          </div>
        </Route>
        <Route exact path='/courses'>
          <Navbar/>
          <div className="otherpages">
            <Sidebar/>
            <Protected Comp={Course}/>
          </div>
        </Route>
        <Route exact path='/newcourse'>
          <Navbar/>
          <div className="otherpages">
            <Sidebar/>
            <Protected Comp={Newcourse}/>
          </div>
        </Route>
        <Route exact path='/promo'>
          <Navbar/>
          <div className="otherpages">
            <Sidebar/>
            <Protected Comp={Promo}/>
          </div>
        </Route>
        <Route exact path='/newpromo'>
          <Navbar/>
          <div className="otherpages">
            <Sidebar/>
            <Protected Comp={Newpromocode}/>
          </div>
        </Route>
        <Route exact path='/login'>
          <Cred/>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
