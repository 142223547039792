import axios from 'axios'
import React, { useEffect, useState } from 'react'
import './student.css'

const Student = () => {
    const [stu,setStu]=useState([])
    useEffect(()=>{
        axios.get(` ${process.env.REACT_APP_API_URL}/user/get`)
         .then((res)=>setStu(res.data)) 
     },[])
    return (
        <div className="invoice">
             <h1 className="topdashboardheading">Students</h1>
             <button className="addnewcourse">Add Course</button>
             <div className="courseshow">
                 <span>
                    <p className="coursedata">Showing 45 Current Courses</p>
                    <p className="basedon">Based your preferences</p>
                 </span>
                 
                 <div className="filter">
                    <i class="fas fa-sort-amount-down"></i>
                    <p className="newest">Newest</p>

                 </div>
             </div>
            <table  className="invoicetable" cellPadding='40'>
                <thead  className="invoicetablehead">
                    <tr className="invoicetableheadrow">
                        <th className="tablecellhead">ID</th>
                        <th className="tablecellhead">Date</th>
                        <th className="tablecellhead">Name</th>
                        <th className="tablecellhead">Email</th>
                        <th className="tablecellhead">Actions</th>
                    </tr>  
                </thead>
                <tbody>
                {stu.map((i)=>{
                        return(
                            <tr className="invoicetableheadrow td">
                            <td className="tabledata idnumber">{i._id}</td>
                            <td className="tabledata">{i.date}</td>
                            <td className="tabledata">{`${i.fname} ${i.lname}`}</td>
                            <td className="tabledata">{i.email}</td>
                            <td className="tabledata" ><i class="fas fa-edit download" onClick={()=>alert('edit')} ></i> <i onClick={()=>alert('delete')} class="fas fa-trash-alt download"></i> <i class="far fa-file-pdf download" onClick={()=>alert('pdf')} ></i> </td>
                       </tr>  
                        )
                    })}     
                    
                </tbody>
            </table>
        </div>
    )
}

export default Student
