import React from 'react'
import Invoice from '../../components/invoice/invoice'

const In = () => {
    return (
        <div>
            <Invoice/>
        </div>
    )
}

export default In
