import axios from 'axios'
import React, { useEffect,useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'


const Promo = () => {
    
    const history=useHistory()
    const [stu,setStu]=useState([])
    useEffect(()=>{
        axios.get(` ${process.env.REACT_APP_API_URL}/promocode/get`)
         .then((res)=>setStu(res.data)) 
     },[])
     console.log(stu)
     const deletehandler=(e)=>{
        e.preventDefault()
        axios.delete(` ${process.env.REACT_APP_API_URL}/promocode/delete/${e.target.parentNode.value}`,{
            headers:{
                "x-access-token":localStorage.getItem("token")
            }
        })
         .then((res)=>{
            toast.success('deleted successfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
         }) 
     }
    return (
        <div className="invoice">
             <h1 className="topdashboardheading">Promo Code</h1>
             <button className="addnewcourse" onClick={()=>history.push('/newpromo')}>Add Promo code</button>
             <div className="courseshow">
                 <span>
                    <p className="coursedata">Showing {stu.length} Current promocodes</p>
                    <p className="basedon">Based your preferences</p>
                 </span>
                 
                 <div className="filter">
                    <i class="fas fa-sort-amount-down"></i>
                    <p className="newest">Newest</p>

                 </div>
             </div>
            <table  className="invoicetable" cellPadding='40'>
                <thead  className="invoicetablehead">
                    <tr className="invoicetableheadrow">
                        <th className="tablecellhead">ID</th>
                        <th className="tablecellhead">Date</th>
                        <th className="tablecellhead">promocode</th>
                        <th className="tablecellhead">discount</th>
                        <th className="tablecellhead">Actions</th>
                    </tr>  
                </thead>
                <tbody>
                {stu.map((i)=>{
                        return(
                            <tr className="invoicetableheadrow td">
                            <td className="tabledata idnumber">{i._id}</td>
                            <td className="tabledata">{i.date}</td>
                            <td className="tabledata">{`${i.promocode}`}</td>
                            <td className="tabledata">{i.discount}</td>
                            <td className="tabledata" ><button style={{backgroundColor:"transparent",border:"none"}} value={i._id} onClick={(e)=>deletehandler(e)}><i  class="fas fa-trash-alt download"></i></button></td>
                       </tr>  
                        )
                    })}     
                    
                </tbody>
            </table>
            <ToastContainer />
        </div>
    )
}

export default Promo
