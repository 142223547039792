import React from 'react'
import './invoice.css'

const Invoice = () => {
    return (
        <div className="invoice">
             <h1 className="topdashboardheading">Invoice</h1>
            <table  className="invoicetable" cellPadding='40'>
                <thead  className="invoicetablehead">
                    <tr className="invoicetableheadrow">
                        <th className="tablecellhead">ID</th>
                        <th className="tablecellhead">Date</th>
                        <th className="tablecellhead">Name</th>
                        <th className="tablecellhead">Email</th>
                        <th className="tablecellhead">Amount</th>
                        <th className="tablecellhead">Actions</th>
                    </tr>  
                </thead>
                <tbody>
                    <tr className="invoicetableheadrow td">
                        <td className="tabledata idnumber">ID</td>
                        <td className="tabledata">Date</td>
                        <td className="tabledata">Name</td>
                        <td className="tabledata">Email</td>
                        <td className="tabledata">Amount</td>
                        <td className="tabledata" onClick={()=>alert('downloaded')}><i class="fas fa-download download"></i></td>
                    </tr>  
                    <tr className="invoicetableheadrow td">
                        <td className="tabledata idnumber">ID</td>
                        <td className="tabledata">Date</td>
                        <td className="tabledata">Name</td>
                        <td className="tabledata">Email</td>
                        <td className="tabledata">Amount</td>
                        <td className="tabledata" onClick={()=>alert('downloaded')}><i class="fas fa-download download"></i></td>
                    </tr>  
                    <tr className="invoicetableheadrow td">
                        <td className="tabledata idnumber">ID</td>
                        <td className="tabledata">Date</td>
                        <td className="tabledata">Name</td>
                        <td className="tabledata">Email</td>
                        <td className="tabledata">Amount</td>
                        <td className="tabledata" onClick={()=>alert('downloaded')}><i class="fas fa-download download"></i></td>
                    </tr>  
                    <tr className="invoicetableheadrow td">
                        <td className="tabledata idnumber">ID</td>
                        <td className="tabledata">Date</td>
                        <td className="tabledata">Name</td>
                        <td className="tabledata">Email</td>
                        <td className="tabledata">Amount</td>
                        <td className="tabledata" onClick={()=>alert('downloaded')}><i class="fas fa-download download"></i></td>
                    </tr>  
                    <tr className="invoicetableheadrow td">
                        <td className="tabledata idnumber">ID</td>
                        <td className="tabledata">Date</td>
                        <td className="tabledata">Name</td>
                        <td className="tabledata">Email</td>
                        <td className="tabledata">Amount</td>
                        <td className="tabledata" onClick={()=>alert('downloaded')}><i class="fas fa-download download"></i></td>
                    </tr>  
                
                </tbody>
            </table>
        </div>
    )
}

export default Invoice
