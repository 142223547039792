import React,{useState} from 'react'

import './newcourse.css'

const Newcourse = () => {

    async function handlerError(res){
        if(!res.ok){
            const message=await res.json();
            // console.log(message)
            throw Error(message)
        }
        return res.json();
    }
    const [maintittle,setMaintittle]=useState('')
    const [sec,setSec]=useState('')
    const [skill,setSkill]=useState('')
    const [price,setPrice]=useState('')
    const [duration,setDuration]=useState('')
    const [lec,setLec]=useState('')
    const [toc,setToc]=useState('')
    const [err,setErr]=useState('')
    const [type,setType]=useState('')

    async function pro(e){
        e.preventDefault()
        const data=JSON.stringify({maintittle:maintittle,sectittle:sec,technologies:skill,duration:duration,price:price,types:type,lectures:lec,typeofcourse:toc})
        console.log(data)
        fetch(`${process.env.REACT_APP_API_URL}/courses/post`,{
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                "x-access-token":localStorage.getItem("token")
            },
            body:data,
        })
         .then(handlerError)
         .then((re)=>{
            //  localStorage.setItem("token",re.token)
            //  history.push("/")
            //  window.location.reload();

        })
         .catch((err)=>{
             if(err){
                 console.log(err)
                 setErr(err.message)
             }else{
                 alert('none')
             }
         })
    }
    return (
        <div className="newcourse" >
            <span className="lph">
                <h1 className="h1">Create Course</h1>
                <i class="fas fa-plus"></i>
            </span>
            
            <hr></hr>
            <form className="mainbox"> 
                <p color="red">{err}</p>
                <span>
                    <input className="newcin" onChange={(e)=>setMaintittle(e.target.value)} placeholder="Main tittle "></input>
                    <input className="newcin" onChange={(e)=>setSec(e.target.value)} placeholder="Sub tittle "></input>
                </span>
                <span>
                    <input className="newcin" placeholder="Skills " onChange={(e)=>setSkill(e.target.value)} ></input>
                    <input className="newcin"  placeholder="type" onChange={(e)=>setToc(e.target.value)} ></input>
                </span>
                <span>
                    <input className="newcin" type="number" placeholder=" price " onChange={(e)=>setPrice(e.target.value)} ></input>
                    <input className="newcin"  placeholder="Duration " onChange={(e)=>setDuration(e.target.value)} ></input>
                </span>
                <span>
                    <input className="newcin" type="number" placeholder=" lectures " onChange={(e)=>setLec(e.target.value)} ></input>
                    <input className="newcin " type="number"  placeholder=" Type " onChange={(e)=>setType(e.target.value)} ></input>
                </span>
                <button type='submit' className="course-btn" onClick={(e)=>pro(e)}>Submit </button>
            </form>
        </div>
    )
}


export default Newcourse
