import React,{ useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const Protectedroute = ({Comp}) => {
    const history=useHistory()
    useEffect(()=>{
        if(!localStorage.getItem('token')){
            history.push('/login')
        }
        
    })
    return (
        <div >
            <Comp/>
        </div>
    )
}

export default Protectedroute
