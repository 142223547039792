import React from 'react'
import { NavLink } from 'react-router-dom'
import './sidebar.css'


const Sidebar = () => {
    function logout(){
        localStorage.clear('token')
        window.location.reload();
    }
    return (
        <>
            <div className="sidebar">
                <ul className="links">
                    <NavLink to='/' exact activeClassName="active" className="link" activeClassnam  ><i class="fas fa-home icons"></i> <p className="linkname">Dashboard</p> </NavLink>
                    <NavLink to='/student' exact activeClassName="active" className="link" activeClassnam  ><i class="fas fa-user-graduate icons"></i> <p className="linkname">Students</p> </NavLink>
                    {/* <NavLink to='/messages' exact activeClassName="active" className="link" activeClassnam  ><i class="fas fa-comments icons"></i> <p className="linkname">Messages</p> </NavLink>
                    <NavLink to='/chatsupport' exact activeClassName="active" className="link" activeClassnam  ><i class="fas fa-envelope-open icons"></i> <p className="linkname">Chat Support</p> </NavLink>
                    <NavLink to='/mail' exact activeClassName="active" className="link" activeClassnam  ><i class="fas fa-at icons"></i> <p className="linkname">Mail</p> </NavLink> */}
                    <NavLink to='/Invoices' exact activeClassName="active" className="link" activeClassnam  ><i class="fas fa-receipt icons"></i> <p className="linkname">Invoices</p> </NavLink>
                    <NavLink to='/courses' exact activeClassName="active" className="link" activeClassnam  ><i class="fas fa-chalkboard-teacher icons"></i> <p className="linkname">Courses</p> </NavLink>
                    <NavLink to='/promo' exact activeClassName="active" className="link" activeClassnam  ><i class="fas fa-blog icons"></i> <p className="linkname">promo</p> </NavLink>
                    <li onClick={logout}  className="link" activeClassnam  ><i class="fas fa-sign-in icons"></i> <p className="linkname">Log Out</p> </li>
                </ul>
            </div>
        </>
    )
}

export default Sidebar
